const listProductsRegalos = [
  {
    id: 1,
    src: require("@/images/regalos/img3.jpg"),
    name: 'Caja de regalo de artista invitado José Carlos Tassara ',
    code: "LM-R001",
    flex: 12,
  },
  {
    id: 2,
    src: require("@/images/regalos/img1.jpg"),
    name: 'Caja de regalo con copa personalizada y más regalos',
    code: "LM-R002",
    flex: 12,
  },
  {
    id: 3,
    src: require("@/images/regalos/img2.jpg"),
    name: 'Caja de regalo con detalle de cactus y más regalos',
    code: "LM-R003",
    flex: 12,
  },

  {
    id: 4,
    src: require("@/images/regalos/img4.jpg"),
    name: 'Caja de regalo con botella de Perú y más regalos',
    code: "LM-R004",
    flex: 12,
  },
  {
    id: 5,
    src: require("@/images/regalos/img5.jpg"),
    name: 'Caja de regalo para oficina',
    code: "LM-R005",
    flex: 12,
  },
  {
    id: 6,
    src: require("@/images/regalos/img6.jpg"),
    name: 'Caja de regalo con frutos secos y planta y más regalos',
    code: "LM-R006",
    flex: 12,
  },
  {
    id: 7,
    src: require("@/images/regalos/img7.jpg"),
    name: 'Caja de regalo con botella de vino y más regalos',
    code: "LM-R007",
    flex: 12,
  },
  {
    id: 8,
    src: require("@/images/regalos/img9.jpg"),
    name: 'Caja de regalo con botella de whisky, libro y más regalos',
    code: "LM-R008",
    flex: 12,
  },
  {
    id: 9,
    src: require("@/images/regalos/img8.jpg"),
    name: 'Caja de regalo con cafetera y más regalos',
    code: "LM-R009",
    flex: 12,
  },
  {
    id: 10,
    src: require("@/images/regalos/img10.jpg"),
    name: 'Caja de regalo con chalina de baby alpaca y más regalos',
    code: "LM-R0010",
    flex: 12,
  },
  {
    id: 11,
    src: require("@/images/regalos/img11.jpg"),
    name: 'Caja de regalo con portaretrato y más regalos',
    code: "LM-R0011",
    flex: 12,
  },
  {
    id: 12,
    src: require("@/images/regalos/img12.jpg"),
    name: 'Caja de regalo con botella de vino y más regalos',
    code: "LM-R0012",
    flex: 12,
  },
  {
    id: 13,
    src: require("@/images/regalos/img15.jpg"),
    name: 'Caja de regalo con chalina 100% alpaca y más regalos',
    code: "LM-R0013",
    flex: 12,
  },
  {
    id: 14,
    src: require("@/images/regalos/img14.jpg"),
    name: 'Caja de regalo cerrada con cinta y un ramo de flores',
    code: "LM-R0014",
    flex: 12,
  },
  {
    id: 15,
    src: require("@/images/regalos/img13.jpg"),
    name: 'Caja de regalo cerrada con cinta y dos ramos de flores',
    code: "LM-R0015",
    flex: 12,
  },
];

export { listProductsRegalos };
